import { Location } from "@reach/router"
import { FooterSimple, FormContactUsV3, Link, Provider, ThemeContext,CXToast } from "Components"
import Menu from "Components/Menu"
import Config, { nav } from "Data"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"
import { loadReCaptcha } from "react-recaptcha-google"
import ScrollToTop from "react-scroll-up"
import { Sticky, StickyContainer } from "react-sticky"
import styled from "styled-components"
import { GlobalStyle, mixins, Nav, ScrollToTopButton } from "Styles"
import { theme } from "Theme"
import logo from "../../static/logo/header-logo-hr.png"

const { colors, fontSize } = theme

const LayoutStyled = styled.div`
  width: 100%;
  ${({ mode }) => mode === "dark" && `color: #FFF;background: #212121;border-color: #eeeeee;`};
`

const HeaderContainer = styled.header`
  ${mixins.flexBetween};
  padding: 0;
  position: ${(props) => (props.sticky ? "relative" : "absolute")};
  top: 0;
  ${({ transparentHeader }) => !transparentHeader && `box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);`}
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  &.header-solid {
    background: ${theme.colors.white.base};
    & > nav {
      button,
      a:not(.action-cta),
      .nav-logo {
        color: ${theme.colors.black.base};
      }
    }
    ${({ mode }) =>
      mode === "dark" &&
      `color: #FFF;background: #212121;border-color: #eeeeee;& > nav {
        button,
        a:not(.action-cta),
        .nav-logo {
          color: ${theme.colors.white.base};
        }
      }`};
  }
`

const LayoutContainer = styled.main`
  margin-top: ${theme.headerScrollHeight};
`

const StyledLink = styled((props) => <Link {...props} />)`
  display: flex;
  font-weight: 700;
  align-items: center;
  &:hover {
    color: inherit;
  }
`

const Navbar = styled(Nav)`
  ${mixins.flexBetween};
  color: ${colors.black.base};
  position: relative;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  z-index: 12;
  min-height: ${theme.headerScrollHeight};
  padding: 0 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

const TextLogo = styled.span`
  font-size: ${fontSize.xxlarge};
`

const ScrollToTopStyled = styled.span`
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: none;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentModal: null,
      shareEvent: null,
      showEvents: false,
      lastScrollTop: 0,
      scrollDirection: "none",
      menuOpen: false,
      isHide: false,
      isMounted: false,
    }
  }

  componentDidMount() {
    loadReCaptcha()
    if (this.captchaObject) {
      this.captchaObject.reset()
      this.captchaObject.execute()
    }

    this.setState({
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    })
    setTimeout(() => this.setState({ isMounted: true }), 100)
  }

  onLoadRecaptcha() {
    if (this.captchaObject) {
      this.captchaObject.reset()
      this.captchaObject.execute()
    }
  }

  toggleModal = (key, param) => (e) => {
    e.preventDefault()
    if (this.state.currentModal) {
      this.handleModalCloseRequest()
      return
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: param.shareEvent,
      showEvents: param.showEvents,
    })
  }

  handleModalCloseRequest = () => {
    this.setState({
      ...this.state,
      currentModal: null,
    })
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen })

  handleMenuClick = (e) => {
    const target = e.target
    const isLink = target.hasAttribute("href")
    const isContainer = target.classList && target.classList[0].includes("MenuContainer")
    if (isLink || isContainer) {
      this.toggleMenu()
    }
  }

  render() {
    const { props } = this
    const { locale, region, children, sticky, transparentHeader, transparentFooter, path, type } = props
    const { scrollDirection, menuOpen, currentModal } = this.state
    return (
      <StaticQuery
        query={graphql`
          query SimpleQuery {
            banners: bannersJson {
              banner {
                attend {
                  childImageSharp {
                    gatsbyImageData(width: 1440, placeholder: BLURRED)
                  }
                }
                sponsor {
                  childImageSharp {
                    gatsbyImageData(width: 1440, placeholder: BLURRED)
                  }
                }
                sharing {
                  childImageSharp {
                    gatsbyImageData(width: 1440, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        `}
        render={() => {
          const page = "Menu"
          const localizedRegion = region === "us" ? "/" : `/${region}/`

          return (
            <>
              <CXToast />
              <FormContactUsV3
                title={`Have a question for the ${Config.brand} team?`}
                subtitle="Call us or submit below:"
                isOpen={currentModal === "modal_contactus"}
                onAfterOpen={this.handleOnAfterOpenModal}
                onRequestClose={this.handleModalCloseRequest}
                closeModal={this.toggleModal("modal_contactus")}
              />
              <Provider>
                <IntlProvider locale={locale}>
                  <ThemeContext.Consumer>
                    {({ mode }) => (
                      <>
                        <Location>
                          {() => (
                            <Fragment>
                              <GlobalStyle />
                              <LayoutStyled mode={mode}>
                                <StickyContainer>
                                  <Sticky disableCompensation>
                                    {({ style, distanceFromTop }) => (
                                      <HeaderContainer
                                        mode={mode}
                                        style={sticky ? style : null}
                                        className={`${"header-solid"}`}
                                        ref={(el) => (this.header = el)}
                                        scrollDirection={scrollDirection}
                                        transparentHeader={transparentHeader}>
                                        {distanceFromTop * -1 > 140 && transparentHeader && (
                                          <Helmet bodyAttributes={{ class: "sticky-inverted" }} />
                                        )}
                                        {distanceFromTop * -1 > 140 && !transparentHeader && (
                                          <Helmet bodyAttributes={{ class: "sticky-default" }} />
                                        )}
                                        <Navbar>
                                          <StyledLink
                                            to={`${localizedRegion}`}
                                            alt={`${Config.brand} Logo`}
                                            track={page}
                                            title={`${Config.brand} Logo`}>
                                            <TextLogo className="nav-logo">
                                              <img
                                                src={logo}
                                                alt=""
                                                style={{ margin: "0", padding: "4px 0", width: "auto", height: "75px" }}
                                              />
                                            </TextLogo>
                                          </StyledLink>
                                        </Navbar>

                                        <Menu
                                          type={type}
                                          navLinks={nav}
                                          menuOpen={menuOpen}
                                          path={path}
                                          handleMenuClick={(e) => this.handleMenuClick(e)}
                                          button_text={`Contact Us`}
                                          button_action={this.toggleModal("modal_contactus", {
                                            shareEvent: null,
                                            showEvents: false,
                                          })}
                                        />
                                      </HeaderContainer>
                                    )}
                                  </Sticky>
                                  <LayoutContainer>{children}</LayoutContainer>
                                </StickyContainer>

                                <ScrollToTopStyled>
                                  <ScrollToTop
                                    showUnder={160}
                                    style={{ zIndex: 9, bottom: 120, right: 48, transitionDuration: "0.4s" }}>
                                    <ScrollToTopButton>
                                      <span>UP</span>
                                    </ScrollToTopButton>
                                  </ScrollToTop>
                                </ScrollToTopStyled>

                                {/* <CookieBannerComponent /> */}

                                <FooterSimple transparentFooter={transparentFooter} />
                              </LayoutStyled>
                            </Fragment>
                          )}
                        </Location>
                      </>
                    )}
                  </ThemeContext.Consumer>
                </IntlProvider>
              </Provider>
            </>
          )
        }}
      />
    )
  }
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
